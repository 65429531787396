import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Page } from '../models/survey.page.model';
import { AnswerChange } from '../models/survey.answerchange.model';
import { SurveyComponentMove } from '../models/survey.surveycomponentmove.models';
import { SurveyComponentDelete } from '../models/survey.syrveycomponentdelete.model';
import { QuestionInPage } from '../models/survey.questioninpage.model';

@Component({
  selector: 'app-survey-content',
  templateUrl: './survey-content.component.html',
  styleUrls: ['./survey-content.component.scss']
})
export class SurveyContentComponent implements OnInit {
  @Input() page: Page;
  @Input() pages: Array<Page>;
  @Output() addQuestionClick = new EventEmitter<number>();
  @Output() addAnswerClick = new EventEmitter<AnswerChange>();
  @Output() moveQuestionClick = new EventEmitter<SurveyComponentMove>();
  @Output() movePageClick = new EventEmitter<SurveyComponentMove>();
  @Output() deleteQuestionClick = new EventEmitter<SurveyComponentDelete>();
  @Output() updateQuestionClick = new EventEmitter<QuestionInPage>();

  public froalaOptions: Object = {
    toolbarInline: true,
    charCounterCount: false,
    toolbarButtons: [
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'fontFamily',
      'fontSize',
      'color',
      'formatBlock',
      'blockStyle',
      'inlineStyle',
      'align',
      '-',
      'insertOrderedList',
      'insertUnorderedList',
      'outdent',
      'indent',
      'selectAll',
      'createLink',
      'insertImage',
      'insertVideo',
      'table',
      'undo',
      'redo',
      'html',
      'save',
      'insertHorizontalRule',
      'uploadFile',
      'removeFormat'
    ],
    toolbarVisibleWithoutSelection: true
  };

  constructor() {}

  AddQuestion(pageIndex: number) {
    this.addQuestionClick.emit(pageIndex - 1);
  }

  AddAnswer(event: AnswerChange) {
    this.addAnswerClick.emit(event);
  }

  MoveQuestion(event: SurveyComponentMove) {
    this.moveQuestionClick.emit(event);
  }

  UpdateQuestion(event: QuestionInPage) {
    this.updateQuestionClick.emit(event);
  }

  DeleteQuestion(event: SurveyComponentDelete) {
    this.deleteQuestionClick.emit(event);
  }

  MovePage(pageIndex: number, direction: string) {
    this.movePageClick.emit(new SurveyComponentMove(pageIndex, null, null, direction));
  }

  ngOnInit() {}
}
