
  export class SurveyComponentDelete {
    PageIndex: number;
    QuestionIndex: number;
    constructor(pageindex: number, questionindex: number) {
      this.PageIndex = pageindex;
      this.QuestionIndex = questionindex;
    }
  }

