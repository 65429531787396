import { Answer } from './survey.answer.model';

  export class Question {
    QuestionText: string;
    private questionKey: string;
    Ordinal: number;
    AnswerType: string;
    Answers: Array<Answer>;
    constructor(questiontext: string, ordinal: number, answertype: string, answers: Array<Answer>) {
      this.QuestionText = questiontext;
      this.Ordinal = ordinal;
      this.AnswerType = answertype;
      this.Answers = answers;
    }

    get QuestionKey(): string {
      return this.questionKey;
    }

    set QuestionKey(val: string) {
      this.questionKey = val;
    }
  }
