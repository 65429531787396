

  export class Answer {
    AnswerText: string;
    private answerKey: string;
    Ordinal: number;
    constructor(answertext: string, ordinal: number) {
      this.AnswerText = answertext;
      this.Ordinal = ordinal;
    }

    get AnswerKey(): string {
      return this.answerKey;
    }

    set AnswerKey(val: string) {
      this.answerKey = val;
    }
  }
