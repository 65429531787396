import { Injectable } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Answer} from './models/survey.answer.model';

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  constructor() { }

  getAnserFormGroup(answer: Answer) {
    return new FormGroup({
      AnswerKey: new FormControl(answer.AnswerKey, Validators.required),
      AnswerText: new FormControl(answer.AnswerText, Validators.required),
      Ordinal: new FormControl(answer.Ordinal, Validators.required)
    });
  }

  reOrder(answers: Answer[]): Answer[] {
    const newAnswers: Answer[] = [...answers];
    newAnswers.forEach((answer, index) => {
      const answerKeySuffix = +answer.AnswerKey.split('_')[2];

      if (answerKeySuffix !== answer.Ordinal) {
        if (answer.Ordinal < answerKeySuffix) {
          if (answers[index - 1]) {
            answers[index - 1].Ordinal = answer.Ordinal + 1;
            this.matchKeytoOrdinal(answers[index - 1]);
          }
        } else {
          if (answers[index + 1]) {
            answers[index + 1].Ordinal = answer.Ordinal - 1;
            this.matchKeytoOrdinal(answers[index + 1]);
          }
        }

        this.matchKeytoOrdinal(answer);
      }
    });

    return newAnswers.sort((answer1: Answer, answer2: Answer) => {
      return answer1.Ordinal - answer2.Ordinal;
    });
  }

  private matchKeytoOrdinal(answer) {
    const answerKeySuffixes = answer.AnswerKey.split('_');
    answerKeySuffixes[2] = answer.Ordinal;
    answer.AnswerKey = answerKeySuffixes.join('_');
  }
}
