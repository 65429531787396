

  export class SurveyComponentMove {
    PageIndex: number;
    QuestionIndex: number;
    componentLength: number;
    Direction: string;
    constructor(pageindex: number, questionindex: number, componentlength: number, direction: string) {
      this.PageIndex = pageindex;
      this.QuestionIndex = questionindex;
      this.componentLength = componentlength;
      this.Direction = direction;
    }
  }
