import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-intro',
  templateUrl: './survey-intro.component.html',
  styleUrls: ['./survey-intro.component.scss']
})
export class SurveyIntroComponent implements OnInit {
  public froalaOptions: Object = {
    toolbarInline: true,
      charCounterCount: false,
      alwaysVisible: true,
      toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
      'fontFamily', 'fontSize', 'color', 'formatBlock', 'blockStyle', 'inlineStyle', 'align', '-',
      'insertOrderedList', 'insertUnorderedList', 'outdent', 'indent', 'selectAll', 'createLink',
      'insertImage', 'insertVideo', 'table', 'undo', 'redo', 'html', 'save', 'insertHorizontalRule',
      'uploadFile', 'removeFormat'],
      toolbarVisibleWithoutSelection: true
  };

  constructor() { }

  ngOnInit() {
  }

}
