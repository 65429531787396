import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SurveyService } from '../survey.service';
import { Page } from '../models/survey.page.model';
import { SurveyComponentMove } from '../models/survey.surveycomponentmove.models';
import { Answer } from '../models/survey.answer.model';
import { Question } from '../models/survey.question.model';
import { AnswerChange } from '../models/survey.answerchange.model';
import { SurveyComponentDelete } from '../models/survey.syrveycomponentdelete.model';
import { QuestionInPage } from '../models/survey.questioninpage.model';

@Component({
  selector: 'app-survey-container',
  templateUrl: './survey-container.component.html',
  styleUrls: ['./survey-container.component.scss']
})
export class SurveyContainerComponent implements OnInit {
  Pages: Array<Page> = [];
  @Output() movePageClick = new EventEmitter<SurveyComponentMove>();

  closeResult: string;

  constructor(private modalService: NgbModal, private surveyService: SurveyService) {}

  open(content) {
    this.modalService.open(content).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  AddNewPage(): void {
    this.Pages.push(new Page('New Page', this.Pages.length + 1));
  }

  AddNewQuestion(pageIndex): void {
    const length = this.Pages[pageIndex].Questions.length;
    this.Pages[pageIndex].Questions.push(new Question('', length + 1, 'Radio', new Array<Answer>(new Answer('', 1))));
    setTimeout(() => {
      const el_id = 'open_content_' + (pageIndex + 1) + '_' + (length + 1);
      const el = document.getElementsByClassName(el_id)[0] as HTMLElement;
      el.click();
    }, 100);
  }

  AddNewAnswer(event: AnswerChange): void {
    this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1].Answers.push(new Answer(' ', event.AnswerIndex));
  }

  UpdateQuestion(event: QuestionInPage) {
    this.Pages[event.PageIndex - 1].Questions[event.Question.Ordinal - 1] = JSON.parse(JSON.stringify(event.Question));
  }

  MoveQuestion(event: SurveyComponentMove) {
    if (event.Direction === 'up') {
      const temp = this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1];
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1] = this.Pages[event.PageIndex - 1].Questions[
        event.QuestionIndex - 2
      ];
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 2] = temp;

      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 2].Ordinal = event.QuestionIndex - 1;
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1].Ordinal = event.QuestionIndex;
    }
    if (event.Direction === 'down') {
      const temp = this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1];
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1] = this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex];
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex] = temp;

      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex].Ordinal = event.QuestionIndex + 1;
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1].Ordinal = event.QuestionIndex;
    }
  }

  MoveAnswer(event: SurveyComponentMove) {
    if (event.Direction === 'up') {
      const temp = this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1];
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1] = this.Pages[event.PageIndex - 1].Questions[
        event.QuestionIndex - 2
      ];
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 2] = temp;

      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 2].Ordinal = event.QuestionIndex - 1;
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1].Ordinal = event.QuestionIndex;
    }
    if (event.Direction === 'down') {
      const temp = this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1];
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1] = this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex];
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex] = temp;

      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex].Ordinal = event.QuestionIndex + 1;
      this.Pages[event.PageIndex - 1].Questions[event.QuestionIndex - 1].Ordinal = event.QuestionIndex;
    }
  }

  MovePage(event: SurveyComponentMove) {
    if (event.Direction === 'up') {
      const temp = this.Pages[event.PageIndex - 1];
      this.Pages[event.PageIndex - 1] = this.Pages[event.PageIndex - 2];
      this.Pages[event.PageIndex - 2] = temp;

      this.Pages[event.PageIndex - 2].Ordinal = event.PageIndex - 1;
      this.Pages[event.PageIndex - 1].Ordinal = event.PageIndex;
    }
    if (event.Direction === 'down') {
      const temp = this.Pages[event.PageIndex - 1];
      this.Pages[event.PageIndex - 1] = this.Pages[event.PageIndex];
      this.Pages[event.PageIndex] = temp;

      this.Pages[event.PageIndex].Ordinal = event.PageIndex + 1;
      this.Pages[event.PageIndex - 1].Ordinal = event.PageIndex;
    }
  }

  DeleteQuestion(event: SurveyComponentDelete) {
    this.Pages[event.PageIndex - 1].Questions.splice(event.QuestionIndex - 1, 1);
    if (this.Pages[event.PageIndex - 1].Questions && this.Pages[event.PageIndex - 1].Questions.length > 0) {
      this.Pages[event.PageIndex - 1].Questions.forEach((q: Question, index: number) => {
        q.Ordinal = index + 1;
      });
    }
  }

  ngOnInit() {
    this.Pages = this.surveyService.getPages();
  }
}
