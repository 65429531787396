import { Question } from './survey.question.model';

export class Page {
    PageText: string;
    Ordinal: number;
    constructor(pagetext: string, ordinal: number) {
      this.PageText = pagetext;
      this.Ordinal = ordinal;
      this.Questions = [];
    }
    Questions: Array<Question>;
  }
