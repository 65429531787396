import { Question } from './survey.question.model';

  export class QuestionInPage {
    PageIndex: number;
    Question: Question;
    constructor(pageindex: number, question: Question) {
      this.PageIndex = pageindex;
      this.Question = question;
    }
  }

