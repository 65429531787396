import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Question} from '../models/survey.question.model';
import {Answer} from '../models/survey.answer.model';
import {QuestionService} from '../question.service';
import {AnswerService} from '../answer.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-question-edit',
  templateUrl: './question-edit.component.html',
  styleUrls: ['./question-edit.component.scss'],
  animations: [trigger('answers', [
    transition(':enter', [
      style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
      animate('0.4s',
        style({ transform: 'scale(1)', opacity: 1 }))  // final
    ]), transition(':leave', [
      style({ transform: 'scale(1)', opacity: 1, height: '*' }),
      animate('0.2s',
        style({
          transform: 'scale(0.5)', opacity: 0,
          height: '0px', margin: '0px'
        }))
    ])
  ])]
})
export class QuestionEditComponent implements OnInit {
  @Input()
  question: Question;
  questionFormGroup: FormGroup;


  @Output()
  submitted: EventEmitter<any> = new EventEmitter();
  @Output()
  cancelled: EventEmitter<any> = new EventEmitter();

  froalaOptions: Object = {
    charCounterCount: false,
    toolbarFixed: true,
    toolbarButtons: [
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'fontFamily',
      'fontSize',
      'color',
      'formatBlock',
      'blockStyle',
      'inlineStyle',
      'align',
      '-',
      'insertOrderedList',
      'insertUnorderedList',
      'outdent',
      'indent',
      'selectAll',
      'createLink',
      'insertImage',
      'insertVideo',
      'table',
      'undo',
      'redo',
      'html',
      'save',
      'insertHorizontalRule',
      'uploadFile',
      'removeFormat'
    ]
  };

  constructor(private fb: FormBuilder,
              private ref: ChangeDetectorRef,
              private questionService: QuestionService,
              private answerService: AnswerService) { }

  get answers(): FormArray {
    return this.questionFormGroup.get('Answers') as FormArray;
  }

  ngOnInit() {
    this.generateQuestionKeys(this.question);
    this.defineForm(this.question);
    this.answers.valueChanges.subscribe((answers: Answer[]) => {
      const newAnswers: Answer[] = this.answerService.reOrder(answers);
      this.answers.reset(newAnswers, { onlySelf: true, emitEvent: false });
      this.questionFormGroup.markAsDirty();
    });
  }

  submit({value, valid}: {value: Question, valid: boolean}) {
    if (valid) {
      this.submitted.emit({data: value});
    }
  }

  cancel() {
    this.cancelled.emit();
  }

  checkAnswerValidity(index: number) {
    return this.answers.controls[index].invalid ||
      this.answers.controls[index].dirty ||
      this.answers.controls[index].touched;
  }

  getAnswerErrors(index: number, key: string) {
    return this.answers.controls[index].get(key).errors;
  }

  addAnswer() {
    const newAnswer = new Answer('', this.answers.length + 1);

    newAnswer.AnswerKey = this.generateAnswerKey(newAnswer);

    this.insertAnswerFormGroup(newAnswer);
  }

  deleteAnswer(index: number) {
    this.answers.removeAt(index);
  }

  private generateQuestionKeys(question: Question) {
    question.QuestionKey = `question_${question.Ordinal}`;
    question.Answers.forEach((answer: Answer) => {
      answer.AnswerKey = this.generateAnswerKey(answer);
    });
  }

  private generateAnswerKey(answer: Answer): string {
    return `answer_${this.question.Ordinal}_${answer.Ordinal}`;
  }

  private defineForm(question: Question) {
    this.questionFormGroup = this.questionService.getQuestionFormGroup(question);
    this.question.Answers.forEach((answer: Answer) => {
      this.insertAnswerFormGroup(answer);
    });
  }

  private insertAnswerFormGroup(answer: Answer) {
    this.answers.push(
      this.answerService.getAnserFormGroup(answer)
    );
  }

}
