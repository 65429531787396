import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CounterComponent),
    multi: true
  }]
})
export class CounterComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
  counter: number;

  @Input()
  max: number;

  @Input()
  min: number;

  minReached: boolean;
  maxReached: boolean;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() { }

  ngOnInit() {
    if (typeof this.min === 'undefined') {
      this.min = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['min'] || changes['max']) {
      this.checkBounds();
    }
  }

  checkBounds() {
    this.minReached = this.maxReached = false;

    if (this.counter === this.min) {
      this.minReached = true;
    }

    if ( this.max && this.counter === this.max) {
      this.maxReached = true;
    }
  }

  increment(evt: any) {
    evt.preventDefault();

    if (typeof this.max !== 'undefined' && this.counter === this.max) {
      return;
    }
    this.counter++;
    this.checkBounds();
    this.onChange(this.counter);
    this.onTouched();
  }

  decrement(evt: any) {
    evt.preventDefault();

    if (this.counter === this.min) {
      return;
    }
    this.counter--;
    this.checkBounds();
    this.onChange(this.counter);
    this.onTouched();
  }

  // ControlValueAccessor interface implementation
  writeValue(value: number) {
    if (typeof value !== 'undefined') {
      this.counter = value;
      this.checkBounds();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
