import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SurveyConcludeComponent } from './survey-conclude/survey-conclude.component';
import { SurveyContentComponent } from './survey-content/survey-content.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { SurveyIntroComponent } from './survey-intro/survey-intro.component';
import { SurveyContainerComponent } from './survey-container/survey-container.component';
import { QuestionComponent } from './question/question.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SurveyService } from './survey.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionEditComponent } from './question-edit/question-edit.component';
import {CounterComponent} from './counter/counter.component';
import {DialogModule} from 'primeng/dialog';

@NgModule({
  declarations: [
    SurveyConcludeComponent, SurveyContentComponent,
    SurveyIntroComponent, SurveyContainerComponent,
    QuestionComponent, QuestionEditComponent,
    CounterComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    FormsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    DialogModule
  ],
  entryComponents: [],
  providers: [SurveyService]
})
export class SurveyModule { }
