import { Injectable } from '@angular/core';
import {Question} from './models/survey.question.model';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor() { }

  getQuestionFormGroup(question: Question) {
    return new FormGroup({
      QuestionKey: new FormControl(question.QuestionKey, Validators.required),
      QuestionText: new FormControl(question.QuestionText, Validators.required),
      Answers: new FormArray([]),
      AnswerType: new FormControl(question.AnswerType, Validators.required),
      Ordinal: new FormControl(question.Ordinal, Validators.required)
    });
  }
}
