import { Injectable } from '@angular/core';
import { Page } from './models/survey.page.model';
import { Answer } from './models/survey.answer.model';
import { Question } from './models/survey.question.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  Pages: Array<Page> = [];

  constructor() {
    this.sampleSurvey();
   }

  sampleSurvey(): Array<Page> {
    const pages: Array<Page> = [];
    const page1 = new Page('Welcome', 1);

    const question1 = new Question(
      'How are you doing today?',
      1,
      'Radio',
      new Array<Answer>(new Answer('Bad', 1), new Answer('Good', 2), new Answer('Excellent', 3))
    );

    const question2 = new Question(
      'Where do you live in USA?',
      2,
      'Checkbox',
      new Array<Answer>(
        new Answer('East Coast', 1),
        new Answer('West Coast', 2),
        new Answer('Midwest', 3),
        new Answer('Pasific Islands', 4)
      )
    );

    page1.Questions.push(question1);
    page1.Questions.push(question2);

    pages.push(page1);

    const question3 = new Question(
      'Where do you live in USA?',
      1,
      'Listbox',
      new Array<Answer>(
        new Answer('East Coast', 1),
        new Answer('West Coast', 2),
        new Answer('Midwest', 3),
        new Answer('Pasific Islands', 4)
      )
    );

    const question4 = new Question(
      'How is the weather today?',
      2,
      'Dropdown',
      new Array<Answer>(new Answer('Sunny', 1), new Answer('Cloudy', 2), new Answer('Rainy', 3), new Answer('Clear sky', 4))
    );

    const page2 = new Page('Details', 2);

    page2.Questions.push(question3);
    page2.Questions.push(question4);

    pages.push(page2);
    this.Pages = pages;
    return pages;
  }

  getPages(): Array<Page> {
    return this.Pages;
  }
}
