// src/app/auth/auth.service.ts

import { Injectable, ɵConsole } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as auth0 from 'auth0-js';

@Injectable()
export class AuthService {

  auth0 = new auth0.WebAuth({
    clientID: 'Crqt2ghBEfMIbBzgLtYcUW90nU3Y2bjn',
    domain: 'gagansawant.auth0.com',
    responseType: 'token id_token',
    redirectUri: 'http://localhost:4200/callback',
    scope: 'openid profile email'
  });

  constructor(public router: Router) {
    console.log('Auth constructor called');
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken');
  }

  get idToken(): string {
    return localStorage.getItem('idToken');
  }

  public login(): void {
    this.auth0.authorize();
  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        this.setSession(authResult);

        this.auth0.client.userInfo(authResult.accessToken, function(err, user) {
          localStorage.setItem('userinfo', user.name);
        });

        this.router.navigate(['/home']);
      } else if (err) {
        this.router.navigate(['/home']);
        console.log(err);
      }
    });
  }

  private setSession(authResult): void {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');
    // Set the time that the access token will expire at
    const expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
    localStorage.setItem('accessToken', authResult.accessToken);
    localStorage.setItem('idToken', authResult.idToken);
    localStorage.setItem('expiresAt', expiresAt.toString());
  }

  public renewSession(): void {
    console.log('Renewsession method called');
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
        this.logout();
      }
    });
  }

  public logout(): void {
    // Remove tokens and expiry time
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('expiresAt');
    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');
    // Go back to the home route
    this.router.navigate(['/']);
  }

  public getProfile(): string {
    let profile: string;
    try {
      profile = localStorage.getItem('userinfo').toString();
    } catch (error) {
      profile = '';
    }
    return profile;
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    // console.log(new Date().toString(), new Date(parseInt(localStorage.getItem('expiresAt'))).toString());
    return new Date().getTime() < parseInt(localStorage.getItem('expiresAt'));
  }

}
