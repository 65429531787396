import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Question} from '../models/survey.question.model';
import {Page} from '../models/survey.page.model';
import {AnswerChange} from '../models/survey.answerchange.model';
import {SurveyComponentMove} from '../models/survey.surveycomponentmove.models';
import {SurveyComponentDelete} from '../models/survey.syrveycomponentdelete.model';
import { QuestionInPage } from '../models/survey.questioninpage.model';
@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  originalQuestion: Question;

  constructor() {
  }

  @Input() question: Question;
  @Input() page: Page;
  @Output() addAnswerClick = new EventEmitter<AnswerChange>();
  @Output() moveQuestionClick = new EventEmitter<SurveyComponentMove>();
  @Output() deleteQuestionClick = new EventEmitter<SurveyComponentDelete>();
  editing: boolean;
  editingQuestionOnPage: number;
  @Output() updateQuestionClick = new EventEmitter<QuestionInPage>();

  MoveQuestion(pageIndex: number, questionIndex: number, componentLength: number, direction: string) {
    this.moveQuestionClick.emit(new SurveyComponentMove(pageIndex, questionIndex, componentLength, direction));
  }

  DeleteQuestion(pageIndex: number, questionIndex: number) {
    this.deleteQuestionClick.emit(new SurveyComponentDelete(pageIndex, questionIndex));
  }

  openModal(pageIndex: number) {
    this.originalQuestion = JSON.parse(JSON.stringify(this.question));
    this.editing = true;
    this.editingQuestionOnPage = pageIndex;
  }

  closeModal(evt) {
    console.log('modal closed with', evt);
    if (evt) {
      this.question = evt.data;
    }
    this.editing = false;
    this.updateQuestionClick.emit(new QuestionInPage(this.editingQuestionOnPage, this.question));
    this.editingQuestionOnPage = null;
  }

  ngOnInit() {
    this.originalQuestion = JSON.parse(JSON.stringify(this.question)); // Deep copy
  }
}
